import React from 'react'
import { Link } from "react-router-dom";
import './landingPage.css';

export const LandingPage = ({ setIsRefresh }) => {
    return <>
        <div className='main-landing'>
            <div className='main-landing-container'>
                <main>
                    <div className="container mb-5">
                        {/* <header > */}
                        <div>
                            <img
                                width="270px"
                                height="100px"
                                alt="logo"
                                src={`${process.env.PUBLIC_URL}/landing-page/assets/LogoEB-blanco.png`}
                            />
                        </div>
                        <div className="row">
                            <div className="col-sm-8 col-md-7 mt-3">
                                <span className="encabezado1-bold">¡Genera tu factura en </span>
                                <br />
                                <span className="encabezado1-normal">3 sencillos pasos!</span>
                                <br /><br />
                                <span className="text-blanco fs-11">
                                    Para facturar usted necesitará tener a la mano:
                                    <ul style={{ marginLeft: 15, marginTop: 5 }}>
                                        <li>RFC</li>
                                        <li>Comprobante de compra o guía de servicio</li>
                                    </ul>
                                    <br />
                                    Nota: solo se podrá facturar dentro del mes correspondiente a su compra o hasta 7 días del siguiente mes.
                                </span>
                            </div>
                            <div className="col-sm-4 col-md-5 d-flex justify-content-end">
                                <img
                                    width="270px"
                                    height="300px"
                                    src={`${process.env.PUBLIC_URL}/landing-page/assets/ceclular.png`}
                                />
                            </div>
                        </div>
                        <div className="mt-5 d-flex justify-content-center container-sub p-4">
                            <span className="encabezado2-normal color-white">¿Qué desea facturar?</span>
                        </div>
                        <div className="mt-3 row gx-3">
                            <div className="col-xs-12 col-sm-6 col-md-6">
                                <CardLanding 
                                    titulo={<span>
                                        Transporte
                                    </span>}
                                    src={`${process.env.PUBLIC_URL}/landing-page/assets/EB.jpg`}
                                />
                                <br />
                                <div className="text-blanco fs-11 text-align-center">
                                    Venta de Boletos
                                    <br/>
                                    Call center 55-44-40-31-87 y 55-44-40-31-06
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12">
                                        <Link onClick={() => setIsRefresh(true)} to="/paqueteria">
                                            <CardLandingItem 
                                                titulo="Paquetería"
                                                src={`${process.env.PUBLIC_URL}/landing-page/assets/ENVIA.jpg`}
                                            />
                                        </Link>
                                        <br />
                                        <div className="text-blanco fs-11 text-align-center">
                                            Paqueteria Envía: 
                                            <br/>
                                            Call center 800 507 5500 y 800 368 4200
                                            <br />
                                            Lunes a Viernes de 8:00 hrs. a 19:00 hrs.
                                            <br />
                                            Sábados de 08:00 hrs. a 17:00 hrs.
                                            <br />
                                            Domingos de 08:00 hrs. a 14:00 hrs.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </div>
    </>
}

const CardLanding = ({ titulo, src, }) => {
    const onClick = () => {
        window.open(process.env.REACT_APP_DEV_URI_URL_EB,  '_blank');
    }
    return <div className="col-xs-12 col-sm-12 col-md-12">
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
                <span
                    onClick={onClick}
                    style={{ cursor: "pointer" }}
                >
                    <CardLandingItem 
                        titulo={titulo}
                        src={src}
                    />
                </span>
            </div>
        </div>
    </div>
}

const CardLandingItem = ({ titulo, src }) => {
    return <div className="text-container-img">
        {titulo}
    </div>
}

const Footer = () => {
    return <div className="footer-landing mt-auto mt-5">
        <div className="container">
            <div className="row gx-3">
            </div>
        </div>
    </div>
}

const FooterRedSocial = ({ className, href }) => {
    return <div className="circulo-rs">
        <a target="_blank" rel="noopener noreferrer" href={href}>
            <i className={`fa-brands ${className}`} />
        </a>
    </div>
}